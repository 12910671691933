<template>
  <div>
    <!-- 平台对账 -->
    <en-table-layout tips :toolbar="false" :tableData="tableData.data" :loading="loading">
      <!-- 搜索条件 -->
      <div slot="tips" class="inner-toolbar">
        <div class="toolbar-btns">
          <!--商户订单时间-->
          <div class="conditions">
            <div>操作时间：</div>
            <el-date-picker
              v-model="advancedForm.times"
              :editable="false"
              style="width:320px;margin-right:10px"
              value-format="timestamp"
              type="daterange"
              :default-time="['00:00:00', '23:59:59']"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :clearable="true"
            ></el-date-picker>
          </div>
          <div class="conditions">
            <el-input v-model="params.name" class="ipt-default" style="width: 280px" placeholder="请输入编号/操作人员名称进行搜索" clearable></el-input>
            <el-button class="btn-default" @click="searchEvent()">筛选</el-button>
          </div>
        </div>
      </div>
      <!-- 表格数据 -->
      <template slot="table-columns">
        <el-table-column prop="log_no" label="编号" />
        <el-table-column prop="member_name" label="操作人员" />
        <el-table-column prop="create_date" label="操作时间">
          <template
            slot-scope="scope"
          >{{ scope.row.create_date | unixToDate('yyyy-MM-dd hh:mm:ss') }}</template>
        </el-table-column>
        <el-table-column prop="ip" label="IP地址"/>
        <el-table-column prop="operation" label="操作记录"/>
        <el-table-column label="操作" fixed="right" width="280" v-if="isMerchant">
          <template slot-scope="scope">
            <el-button v-if="scope.row.type==1" size="mini" type="text" @click="handleInfo(scope.row)">查看商户</el-button>
          </template>
        </el-table-column>
      </template>
      <!-- 表格分页 -->
      <el-pagination
        v-if="tableData"
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="tableData.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"
      ></el-pagination>
    </en-table-layout>
  </div>
</template>

<script>
import request from '@/utils/request'

export default {
  name: "activity-ops-log",
  data() {
    return {
      /** 列表loading状态 */
      loading: false,
      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 10,
      },
      /** 列表数据 */
      tableData: "",
      /** 高级搜索数据 */
      advancedForm: {
        times: "", // 时间
      },
      isMerchant: 0
    };
  },
  mounted() {
    // const end = new Date();
    // const start = new Date();
    // this.advancedForm.times = [start, end];
    this.searchEvent();
    if (window.location.href.indexOf('merchant') >= 0) {
      this.isMerchant = 1;
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.searchEvent();
    next();
  },
  methods: {
    handleInfo(row) {
      this.$router.push(`/merchant/merchant-apply-detail/${row.id}?isRefuse=1`);
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_List();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_List();
    },

    /** 搜索事件触发 */
    searchEvent() {
      this.params.page_no = 1;
      delete this.params.start_time;
      delete this.params.end_time;
      if (this.advancedForm.times) {
        this.params.start_time = this.advancedForm.times[0] / 1000
        this.params.end_time = this.advancedForm.times[1] / 1000
      }
      this.params.type = 0;
      if (window.location.href.indexOf('merchant') >= 0) {
        this.params.type = 1;
      } else if (window.location.href.indexOf('platform') >= 0) {
        this.params.type = 3;
      }
      this.GET_List();
    },
    generateTimeReqestNumber(date1) {
      const date = new Date(date1);
      return (
        date.getFullYear().toString() +
        this.pad2(date.getMonth() + 1) +
        this.pad2(date.getDate()) +
        this.pad2(date.getHours()) +
        this.pad2(date.getMinutes()) +
        this.pad2(date.getSeconds())
      );
    },
    pad2(n) {
      return n < 10 ? "0" + n : n;
    },
    /** 获取对账列表 */
    GET_List() {
      this.loading = true;
      const { params } = this;
      Object.keys(params).forEach((key) => {
        if (!params[key] && params[key] !== 0) {
          delete params[key];
        }
      });
      request({
        url: 'admin/systems/sysLog',
        method: 'get',
        loading: false,
        params
      })
        .then((response) => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
.el-date-editor.el-input {
  width: 180px;
}

::v-deep .form-item-sex .el-form-item__content {
  width: 180px;
}

::v-deep .form-item-region .el-form-item__content {
  min-width: 180px;
}
::v-deep .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 30px;
}
div.toolbar-btns {
  display: contents;
}
div.toolbar-btns .conditions {
  margin-right: 0px;
}
</style>
