<template>
  <div class="page-platform-ops-log">
    <log/>
  </div>
</template>

<script lang="js">
import PlatformOpsLogPage from './platform-ops-log';

export default PlatformOpsLogPage;
</script>

<style lang="scss">
@import "./platform-ops-log";
</style>
