import { Component, Vue } from 'vue-property-decorator';
import Log from '../../activity/activity-ops-log/activity-ops-log.vue';

@Component({
  components: { Log }
})
class PlatformOpsLogPage extends Vue {
  title = 'platform-ops-log';
}

export default PlatformOpsLogPage;
